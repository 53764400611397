import React, { useState, useEffect } from 'react';
import { Menu, Layout, Drawer } from 'antd';
//引入图标组件,注意路径
import MyIcon from '@/assets/MyIcon.js'
import logo from '@/assets/images/logo.png';
import { useHistory, NavLink } from 'react-router-dom';
import route from '../routes/modules/userCenter';
import api from '@/api/apiList'

const { Sider } = Layout;
const App = (props) => {
    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(0);
    const [inde, setIndex] = useState(0);
    const [isMenu, setIsMenu] = useState(0);
    const [menuList, setMenuList] = useState([]);
    const [placement, setPlacement] = useState('left');
    const history = useHistory();


    const showDrawer = (item, index) => {
        setIsActive(index)
        if (item.children && item.children.length === 0) {
            setOpen(false);
            history.push('/');
        } else {
            setMenuList(item.children)
            if (open) {
                setOpen(false);
            }
            setOpen(true);

        }

    };
    const onClose = () => {
        setOpen(false);
    };
    const goTo = (item, index) => {

        menuList.map(i => {
            if (i.children && i.children.length) {
                i.children.map(e => {
                    delete e.isMenu
                })
            }
        })
        item.isMenu = true
        props.addTabs({
            activeKey: item.route,
            title: item.name,
            isCloseOpen: true
        })
        props.history.push(item.route)
        setOpen(false);
    };
    const [todos, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const res = await api.app.menuList({ dataType: 1004 })
            let data = res && res.result !== null ? res.result.menuInfo && res.result.menuInfo.menuList : []
            let todos = JSON.parse(data)
            // console.log(todos, 8888)
            setData(todos);
        };
        fetchData();
    }, [])

    return (
        <>
            <Sider width="72px" trigger={null} className="yzh-aside" collapsible collapsed={false}>
                <div className="yzh-logo">
                    <img src={logo} style={{ height: '60px' }} className="logo" alt="logo" />
                </div>
                <div className="yzh-Menu">
                    {todos.length > 0 && todos.map((item, index) => (
                        <dl onClick={() => showDrawer(item, index)} className={`${isActive === index ? 'active' : 'inActive'}`} key={index}>
                            <dt><MyIcon type={item.icon} className="logo" color='#F76700' /> </dt>
                            <dd >{item.name}</dd>
                        </dl>
                    ))}
                </div>
            </Sider>
            <Drawer
                placement={placement}
                onClose={onClose}
                open={open}
                size={'large'}
                headerStyle={{ display: 'none' }}
                key={placement}
            >
                {menuList.map((item, index) => (
                    <div className='subMenuList' key={index}>
                        <div className='title'>
                            {item.name}
                        </div>
                        <div className='menuList'>
                            {item.children.map((i, dex) => (
                                <span onClick={() => goTo(i, dex)} className={`${i.isMenu ? 'activeMenu' : 'inActiveMenu'}`} key={dex}>{i.name}</span>
                            ))}
                        </div>
                    </div>
                ))}
            </Drawer>
        </>
    );
};
export default App;
