import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {
    Input,
    Select,
    Form, message,
    Modal
} from 'antd';
import PropTypes from 'prop-types';
import { YzhSelect, YzhTree, YzhCitySelect } from '@com'
const YzhDialog = forwardRef((props, formRef) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        visible: false,
        ...props
    });
    useEffect(() => {
        setState({
            ...state,
            visible: props.visible
        })
        props.values && form.setFieldsValue({
            ...props.values
        })
    }, [props.visible])
    const onOk = (values) => {
        if (props.form) {
            form
                .validateFields()
                .then((values) => {
                    props.onOk(values).then(() => {
                        setState({
                            ...state,
                            visible: false,
                        })
                        props.form && form.resetFields()
                    }).catch(() => { })
                })
                .catch((info) => {
                    // console.log('Validate Failed:', info);
                });
        } else {
            props.onOk(values).then(() => {
                setState({
                    ...state,
                    visible: false,
                })
            }).catch(() => { })
        }
    }
    const onCancel = () => {
        props.onCancel().then(() => {
            setState({
                ...state,
                visible: false,
            })
            props.form && form.resetFields()
        })
    }
    const RenderFormItems = (formItems) => {
        return formItems.map(el => {
            let formItem = null
            el.ref = useRef()
            if (el.hidden) return null
            switch (el.type) {
                case 'input':
                    formItem = <Input ref={el.ref} {...el.attrs} />
                    if (el.textarea) {
                        formItem = <Input.TextArea ref={el.ref} {...el.attrs} />
                    }
                    break;
                case 'select':
                    formItem = <Select ref={el.ref} {...el.attrs} />
                    if (el.remoteConfig) {
                        let params = {
                            ...el.remoteConfig.params
                        }
                        if (props.values && props.values[el.name] && props.values[el.name].length) {
                            if (el.attrs?.mode && !el.remoteConfig?.method) {
                                params.ids = props.values[el.name]?.join(',')
                            } else {
                                params.ids = props.values[el.name]
                            }
                        }
                        formItem = <YzhSelect
                        ref={el.ref}
                        { ...el.remoteConfig }
                        params={params}
                        {...el.attrs}
                        onSelect={(selectedKeys, row) => {
                            el.value = selectedKeys || undefined
                            if (el.rkey) {
                                el.attrs.onSelect && el.attrs.onSelect(selectedKeys, el.rkey, row).then(() => {
                                    let rkeyObj = formItems.filter(val => val.name === el.rkey)[0] || {}
                                    form.setFieldsValue({
                                        [el.name]: el.value
                                    })
                                    form.resetFields([el.rkey])
                                    rkeyObj.ref?.current?.handleSearch('')
                                }).catch(() => {})
                            } else {
                                el.attrs.onSelect && el.attrs.onSelect(selectedKeys, row)
                            }
                        }}
                         />
                    }
                    if (el.tree) {
                        let {filterOption, ...attrs} = el.attrs
                        formItem = <YzhTree
                        select={true}
                        {...attrs}
                        onSelect={(selectedKeys, row) => {
                            el.value = selectedKeys || undefined
                            if (el.rkey) {
                                el.attrs.onSelect && el.attrs.onSelect(selectedKeys, el.rkey, row).then(() => {
                                    let rkeyObj = formItems.filter(val => val.name === el.rkey)[0] || {}
                                    form.setFieldsValue({
                                        [el.name]: el.value
                                    })
                                    form.resetFields([el.rkey])
                                    rkeyObj.ref?.current?.handleSearch('')
                                }).catch(() => {})
                            } else {
                                el.attrs.onSelect && el.attrs.onSelect(selectedKeys, row)
                            }
                        }} />
                    }
                    break;
                case 'citySelect':
                    formItem = <YzhCitySelect {...el.attrs} placeholder="请选择"  />
                    break;
                case 'component':
                    formItem = el.component
                    break;
                default:
                    break;
            }
            if (el.render) {
                formItem = el.render(props.values, el)
            }
            const formTag = (params = {}) => <Form.Item
            key={el.name}
            style={{
                width: '100%',
                margin: 0,
                ...(props.formItemStyle || {}),
                ...(params.style || {}),
                ...(el.style || {}),
            }}
            // shouldUpdate
            name={el.name}
            label={el.prepend || el.append ? '' : el.label}
            rules={el.rules}
            wrapperCol={{ span: 24, ...(el.wrapperCol || {}), ...(params.wrapperCol || {}) }}
            initialValue={el.initialValue}
            >{formItem}</Form.Item>
            if (el.prepend || el.append) {
                return <Form.Item key={el.name} label={el.label}>
                    <div style={{display: 'flex'}}>
                        {el.prepend && el.prepend(el)}
                        {formTag({
                            style: {
                                width: 'auto',
                                margin: 0
                            },
                            wrapperCol: {
                                span: 24
                            }
                        })}
                        {el.append && el.append(el)}
                    </div>
                </Form.Item>
            }
            return formTag()
        }).filter(el => el)
    }
    const { visible } = state
    return (
        <Modal
            maskClosable={false}
            centered
            width={460}
            visible={visible}
            {...props.props}
            wrapClassName={`yzh-modal ${props.props.wrapClassName}`}
            onOk={onOk}
            onCancel={onCancel}
        >
            {props.form ? <Form
                name={props.name || 'dialogForm'}
                form={form}
                ref={formRef}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={{}}
                {...props.form}
            >
                {props.formItems ? RenderFormItems(props.formItems) : null}
                {props.children}
            </Form> : props.children}
        </Modal>
    );
})
YzhDialog.propTypes = {
    visible: PropTypes.oneOfType([PropTypes.bool.isRequired]),
    form: PropTypes.oneOfType([PropTypes.object]),
    onOk: PropTypes.oneOfType([PropTypes.func]),
    props: PropTypes.oneOfType([PropTypes.object]),
    onCancel: PropTypes.oneOfType([PropTypes.func]),
    // optionalArray: PropTypes.array, //检测数组类型
    // optionalBool: PropTypes.bool, //检测布尔类型
    // optionalFunc: PropTypes.func, //检测函数（Function类型）
    // optionalNumber: PropTypes.number, //检测数字
    // optionalObject: PropTypes.object, //检测对象
    // optionalString: PropTypes.string, //检测字符串
    // optionalSymbol: PropTypes.symbol, //ES6新增的symbol类型
}
YzhDialog.displayName = 'YzhDialog'

export default YzhDialog