
import { baseUrl, appUrl } from '@/api/baseUrl'

// const baseUrl = '/lrk'
const prefix = '/application'
const urls = {
    menuList: `${baseUrl}/session/queryUserInfo/partskip`,
    list: `${baseUrl}${prefix}/pageList`,
    home: `${baseUrl}${prefix}/pageList/partskip`,
    add: `${baseUrl}${prefix}/add`,
    edit: `${baseUrl}${prefix}/edit`,
    sort: `${baseUrl}${prefix}/applicationSort`,
    info: `${baseUrl}${prefix}/queryApplicationBaseInfo`,
    remove: `${baseUrl}${prefix}/remove`,
    getYtHpByOrder: `${appUrl}/order/sbyh/getYtHpByOrder`,
    queryCount: `${appUrl}/stockcenter/safetyInventory/queryCount/authskip`,
}
export default urls
