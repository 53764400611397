import { storage } from '@/utils/storage'
import routes from '@/routes'
import api from '@/api/apiList'
import { parseQueryToString, setRouterByRoles, setTreeProps, deepClone } from '@/utils'
import { getOpenKeys, getPanes, getAliveApps } from './tools'
import { apps, getFullLink } from '@/micro-app'
import {
    notification
} from 'antd';
import {
    CheckCircleOutlined,
} from '@ant-design/icons';
import config from '@/config'
export const filterTabs = (state, action) => {
    const history = action.history || { location: {} }
    const newState = Object.assign({}, state)
    let { panes, aliveApps, selectedKeys, defaultSelectedKeys, defaultOpenKeys, openKeys } = newState;
    const type = action.value
    let { activeKey } = newState;
    let newPanes = panes
    if (type === '1') { // 关闭当前页签
        if (activeKey === '/') return {
            panes: newPanes,
            activeKey: activeKey,
            history,
            openKeys: ['/']
        }
        newPanes = panes.filter(el => el.key !== activeKey || el.key === '/')
        // aliveApps = aliveApps.filter(el => el.path !== activeKey)
        activeKey = newPanes[newPanes.length - 1].key
        aliveApps = storage.get('aliveApps')
        let pane = newPanes[newPanes.length - 1]
        let path = activeKey + parseQueryToString(pane.query || {})
        aliveApps = getAliveApps({
            activeKey,
            path,
            ...pane,
            aliveApps
        })
        history.push(newPanes[newPanes.length - 1].key)
        openKeys = getOpenKeys(activeKey)
    }
    if (type === '2') { // 关闭其他页签
        newPanes = panes.filter(el => el.key === activeKey || el.key === '/')
        aliveApps = aliveApps.filter(el => el.path === activeKey)
        // history.push(activeKey)
        openKeys = state.openKeys
    }
    if (type === '3') { // 关闭所有页签
        newPanes = [{ title: '首页', key: '/', closable: false }]
        activeKey = '/'
        aliveApps = []
        selectedKeys = ['/']
        defaultSelectedKeys = ['/']
        defaultOpenKeys = []
        openKeys = ['/']
    }
    storage.set('tabList', newPanes)
    storage.set('aliveApps', aliveApps)
    return {
        panes: newPanes,
        aliveApps,
        activeKey,
        history,
        selectedKeys, defaultSelectedKeys, defaultOpenKeys,
        openKeys: openKeys
    }
}
export const addTabs = (state, action) => {
    const history = action.history
    const newState = Object.assign({}, state)
    let { panes, aliveApps, routes: remoteRoutes } = newState;
    let iframeLimit = 20
    let {
        activeKey,
        title,
        defaultSelectedKeys,
        keyPath,
        selectedKeys,
        query,
        code,
        pcode,
        defaultOpenKeys } = action
    panes = getPanes({ activeKey, panes, title, code, pcode, query, remoteRoutes })
    // console.log(activeKey, history, 'activeKey');
    if (activeKey && title) {
        let path = activeKey + parseQueryToString(query)
        aliveApps = getAliveApps({
            activeKey,
            title,
            query,
            code,
            path,
            aliveApps,
            pcode
        })
        if (aliveApps.length > iframeLimit) {
            let limitTimestamp = Math.min(...aliveApps.map(el => el.timestamp))
            panes = panes.filter(val => !aliveApps.filter(el => limitTimestamp === el.timestamp).map(el => el.path).includes(val.key))
            aliveApps = aliveApps.filter(el => limitTimestamp !== el.timestamp)
        }
        panes.map(el => {
            el.isActive = false
            if (el.key === activeKey) {
                el.isActive = true
                el.isTabChange = false
            }
        })
        storage.set('tabList', panes)
        storage.set('aliveApps', aliveApps)
        // 是否关闭打开页签
        setTimeout(() => {
            console.log(path, 'path')
            !action.isCloseOpen && history.push(path)
        }, 100);
    }

    return {
        panes,
        activeKey,
        keyPath,
        aliveApps,
        selectedKeys,
        defaultSelectedKeys,
        defaultOpenKeys,
        history: history || {}
    }
}
export const removeTabs = (state, action) => {
    const history = action.history
    const newState = Object.assign({}, state)
    let { activeKey, openKeys } = newState;
    const targetKey = action.activeKey
    let lastIndex;
    newState.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
            lastIndex = i - 1;
        }
    });
    const panes = newState.panes.filter(pane => pane.key !== targetKey);
    let query = ''
    let path = activeKey
    if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
            activeKey = panes[lastIndex].key;
            query = panes[lastIndex].query
        } else {
            activeKey = panes[0].key;
            query = panes[0].query
        }
        path = activeKey + parseQueryToString(query || {})
        // 是否关闭打开页签
        setTimeout(() => {
            !action.isCloseOpen && history.push(path)
        }, 100);
    }
    // console.log(panes, activeKey, targetKey, 'panes');
    openKeys = getOpenKeys(activeKey)
    // aliveApps = aliveApps.filter(el => el.path !== targetKey)
    let pane = panes.filter(el => el.key === activeKey)
    let aliveApps = storage.get('aliveApps')
    aliveApps = getAliveApps({
        activeKey,
        path,
        ...pane,
        aliveApps
    })
    panes.map(el => {
        el.isActive = false
        if (el.key === activeKey) {
            el.isActive = true
        }
    })
    storage.set('tabList', panes)
    storage.set('aliveApps', aliveApps)
    return {
        panes,
        aliveApps: aliveApps.filter(el => el.path !== targetKey),
        activeKey,
        openKeys,
        history
    }
}
export const changeTabs = (state, action) => {
    const newState = Object.assign({}, state)
    let { aliveApps, panes } = newState
    let { activeKey, isTabChange = false, openKeys, isOpen } = action
    if (isOpen) return {
        ...newState, openKeys
    }
    const pane = panes.filter(pane => pane.key === activeKey);
    let query = pane[0] && pane[0].query || {}
    let queryStr = parseQueryToString(query)
    panes.map(el => {
        el.isActive = false
        if (el.key === activeKey) {
            el.isActive = true
            el.isTabChange = isTabChange
            el.query = query
        }
    })
    let path = activeKey + queryStr
    aliveApps = getAliveApps({ activeKey, query, path, aliveApps })
    aliveApps.map(el => {
        el.isActive = false
        if (el.path === activeKey) {
            el.isActive = true
            el.isTabChange = isTabChange
            el.timestamp = new Date().getTime()
        }
    })
    if (action.history) {
        action.history.push(activeKey + queryStr)
    }
    storage.set('aliveApps', aliveApps)
    storage.set('tabList', panes)
    return {
        aliveApps,
        panes,
        activeKey: activeKey || newState.activeKey,
        selectedKeys: action.selectedKeys || [activeKey],
        openKeys,
    }
}

export const logout = async (state, action) => {
    await api.userCenter.user.logout({ isLogout: action.value?.isLogout })
    storage.set('tabList', routes.filter(el => el.affix).map(el => ({
        key: el.path,
        title: el.meta.title,
        closable: !el.affix,
        meta: el.meta
    })))
    storage.remove('token', 'cookie')
    storage.remove('userInfo')
    storage.remove('aliveApps')
    action.history.push('/login')
}

export const setUserInfo = (state, action) => {

    let userInfo = {
        ...state.userInfo,
        ...action.value
    }
    const args = {
        message: '登录成功！',
        top: 64,
        icon: <CheckCircleOutlined className="primary" />,
        description:
            `欢迎使用${config.setting.title}服务`,
        duration: 3,
    };
    let data = action.value
    let newState = {
        ...data,
        panes: action.value.panes || state.panes,
        aliveApps: action.value.aliveApps || state.aliveApps,
        routes: userInfo.routes,
        userInfo
    }

    if (data) {
        storage.set('tabList', [
            { title: '首页', key: '/', meta: { roles: ['user_index'] }, closable: false }
        ])
        storage.remove('aliveApps')

        newState = {
            ...data,
            aliveApps: [],
            panes: [
                { title: '首页', key: '/', meta: { roles: ['user_index'] }, closable: false }
            ],
            userInfo: {
                ...data,
            }
        }
    } else {
        storage.set('tabList', routes.filter(el => el.affix).map(el => ({
            key: el.path,
            title: el.meta.title,
            closable: !el.affix,
            meta: el.meta
        })))
    }
    notification.open(args);
    storage.set('userInfo', newState)
    return {
        ...newState
    }
}