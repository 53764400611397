import React, { createRef } from 'react';
import { Route, withRouter } from 'react-router-dom' // 引入路由组件
import routes from '@/routes'
// 路由高阶组件
import FrontendAuth from '@/routes/FrontendAuth'

import Aside from '@/layout/aside'
import TabsBar from '@/layout/tabs'
import YzhHeader from '@/layout/header'
import YzhFooter from '@/layout/footer'

import { Layout, Progress, notification } from 'antd';
import { debounce } from '@/utils'
import { storage } from '@/utils/storage'
import { connect } from 'react-redux'
import config from '@/config'
import axios from '@/api/axios'
import { baseUrl, appUrl } from '@/api/baseUrl'
import api from '@/api/apiList'
import AnimatedSwitch from '@/hooks/AnimatedSwitch'
import {
    ADD_TABS,
    REMOVE_TABS,
    CHANGE_TABS,
    FILTER_TABS,
} from '@/store/actionTypes'
import YzhProgressDialog from '@com/YzhProgressDialog'
const { Content } = Layout;
const setScroll = (props, state) => {
    let clientHeight = document.body.clientHeight
    const { showTabsBar, showFooter, tabsLayout } = props.themeConfig
    clientHeight = showTabsBar && tabsLayout === 'vertical' ? clientHeight + 44 : clientHeight
    clientHeight = showFooter ? clientHeight + 50 : clientHeight
    clientHeight = !showTabsBar && !showFooter ? clientHeight + 44 : clientHeight
    clientHeight = !showTabsBar && showFooter ? clientHeight + 50 : clientHeight
    // console.log(clientHeight, showTabsBar, showFooter, tabsLayout, 'clientHeight');
    return {
        themeConfig: props.themeConfig,
        scroll: { x: 1500, y: clientHeight > 600 ? clientHeight : 600 }
    }
}
let tokenTimer = null
class BaseLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            progressDialogRef: React.createRef(),
            progressText: '导出',
            collapsed: false,
            identifyKey: '',
            isFullscreen: false,
            themeConfig: props.themeConfig,
            showThemePanel: false,
            hideTheme: false,
            isRefresh: false,
            userInfo: '',
            aliveApps: props.aliveApps,
            loading: props.loadingIframe || true,
            isIframeActive: props.isIframeActive,
            tabsLayout: props.themeConfig.tabsLayout, // vertical、inline
            scroll: { x: 1500, y: 500 }
        }
        this.history = this.props.history
        this.iframe = createRef([])
    }
    // 展开收起菜单事件处理函数
    toggleMenu = (collapsed) => {
        this.setState({
            collapsed,
        });
        // this.props.changeTabs({
        //     ...this.state,
        //     openKeys: []
        // })
    };
    reload = () => {
        this.setState({
            isRefresh: true
        });
        setTimeout(() => {
            this.setState({
                isRefresh: false
            });
            const { isIframeActive } = this.props
            if (isIframeActive) {
                // eslint-disable-next-line no-self-assign
                window.location.href = window.location.href
            }
        }, 200);
    };
    initUserInfo = () => {
        if (storage.get('token', 'cookie')) return
        const query = new URLSearchParams(location.search)
        const thirdLogin = async (code, jfCode) => {
            let res = await axios.post(`${baseUrl}/thirdLogin/authSkip`, { id: 1, code, jfCode, loading: true, text: '正在登录，请稍后...' })
            if (res && res.success) {
                // 登陆成功，初始化数据
                storage.set('token', encodeURIComponent(res.result.token), 'cookie')
                const res2 = await api.app.menuList({ dataType: 1001 })
                const res3 = await api.app.menuList({ dataType: 1003 })
                let newData = Object.assign({}, res.result, res2.result && res2.result.baseInfo, res3.result && res3.result.btnInfo)
                await this.props.setUserInfo(newData)
            } else {
                this.props.history.push('/login')
            }
        }
        if (query.get('code') && query.get('jfCode')) {
            thirdLogin(query.get('code'), query.get('jfCode'))
        } else {
            this.props.logout({ isLogout: true })
        }
    }
    //如果条件不存在必须要返回null
    static getDerivedStateFromProps (props, current_state) {
        if (props.themeConfig.showTabsBar !== current_state.themeConfig.showTabsBar ||
            props.themeConfig.tabsLayout !== current_state.themeConfig.tabsLayout ||
            props.themeConfig.showFooter !== current_state.themeConfig.showFooter) {
            // console.log(setScroll(props, current_state), 'setScroll(props, current_state)');
            return setScroll(props, current_state)
        }
        if (props.aliveApps.length !== current_state.aliveApps.length) {
            return {
                loadingIframe: true
            }
        }
        return null
    }
    componentDidMount () {
        this.setState({
            ...setScroll(this.props, this.state)
        })
        window.addEventListener('resize', debounce((e) => {
            // console.log(e.target.innerWidth, 'resize');
            if (e.target.innerWidth < 990) {
                this.setState({
                    collapsed: true
                })
            } else {
                this.setState({
                    collapsed: false
                })
            }
            this.setState({
                ...setScroll(this.props, this.state)
            })
        }, 300));
        // 接收iframe事件
        window.addEventListener('message', (e) => {
            // console.log(e.data, 'postMessage data')
            // 关闭页签
            if (e.data.action === 'closeTag') {
                // this.props.removeTabs({
                //     ...this.state,
                //     ...e.data,
                //     activeKey: e.data.path
                // })
                this.props.removeTabs(e.data.path)
                return
            }
            if (e.data.path) {
                //防止缓存
                // console.log(e.data, 'e.data.path')
                // if (e.data && e.data.query && !e.data.query.time) {
                //      this.props.removeTabs(e.data.path)
                // }
                if (this.props.panes.includes(e.data.path) && e.data && e.data.query && !e.data.query.time) {
                    this.props.removeTabs(e.data.path)
                }
                this.props.addTabs({
                    ...this.state,
                    ...e.data,
                    activeKey: e.data.path
                })
            }
            // 监听iframe登录失效，退出登录
            if (e.data.isLogout) {
                storage.remove('token', 'cookie')
                this.props.logout({ isLogout: true })
            }
            if (e.data.identifyKey) {
                this.setState({
                    progressText: e.data.title,
                    identifyKey: e.data.identifyKey
                })
                this.state.progressDialogRef.current.start(() => {
                    console.log('success');
                })
            }
        })
        window.addEventListener('storage', (e) => {
            if (!storage.get('userInfo')) {
                storage.remove('token', 'cookie')
                this.props.logout({ isLogout: true })
            }
        })
        this.initUserInfo()
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return
        }
    }
    render () {
        const { collapsed, isRefresh, scroll, progressDialogRef, identifyKey } = this.state
        const { showTabsBar, showFooter, tabsLayout } = this.props.themeConfig
        const { location, aliveApps, isIframeActive } = this.props
        return (
            <Layout className="yzh-layout">
                <Route render={props => {
                    return <Aside {...{ ...props, ...this.props, collapsed }}></Aside>
                }}>
                </Route>
                <Layout className="site-layout">
                    <YzhHeader reload={this.reload} toggleMenu={this.toggleMenu} {...{ ...this.props, collapsed }} />
                    {showTabsBar && tabsLayout === 'inline' ? <Route render={props => {
                        return <TabsBar layout={tabsLayout} {...this.props} {...props}></TabsBar>
                    }}></Route> : null}
                    <Content
                        className="yzh-content"
                        style={{
                            // padding: 24,
                            // maxHeight: `calc(100vh - ${showTabsBar ? '132px' : '82px'})`,
                            maxHeight: `calc(100vh - 82px)`,
                            minHeight: 280,
                        }}
                    >
                        {!isIframeActive ? <AnimatedSwitch className="yzh-main">
                            {!isRefresh ? routes.filter(el => !(el.meta && el.meta.hideLayout) && !el.iframe).map((v, i) => {
                                if (v.exact) {
                                    return <FrontendAuth exact
                                        {...this.state}
                                        {...this.props}
                                        reload={this.reload}
                                        scroll={scroll}
                                        path={v.path}
                                        title={v.meta.title}
                                        key={i}
                                        routerConfig={v}
                                        location={this.history.location} />
                                }
                                if (v.children) {
                                    return v.children.map(child => <FrontendAuth
                                        {...this.state}
                                        {...this.props}
                                        reload={this.reload}
                                        scroll={scroll}
                                        path={child.path}
                                        title={child.meta.title}
                                        key={i}
                                        routerConfig={child}
                                        location={this.history.location} />)
                                }
                                return <FrontendAuth
                                    {...this.state}
                                    {...this.props}
                                    path={v.path}
                                    routerConfig={v}
                                    reload={this.reload}
                                    title={v.meta.title}
                                    scroll={scroll}
                                    key={i}
                                    history={this.props.history}
                                    location={this.history.location} />
                            }) : null}
                        </AnimatedSwitch> : null}
                        {aliveApps && aliveApps.length ? <div className="yzh-main" style={{ display: isIframeActive ? 'block' : 'none' }}>
                            {
                                aliveApps.map((ele, index) =>
                                    <FrontendAuth
                                        {...this.state}
                                        {...this.props}
                                        aliveApps
                                        isIframeActive
                                        reload={this.reload}
                                        scroll={scroll}
                                        path={ele.path}
                                        title={ele.title}
                                        routerConfig={ele}
                                        location={this.history.location}
                                        key={ele.path} />
                                )
                            }
                        </div> : null}
                    </Content>
                    {showFooter && <YzhFooter />}
                </Layout>
                {identifyKey && <YzhProgressDialog
                    ref={progressDialogRef}
                    text={this.state.progressText}
                    dialogType="notification"
                    onSuccess={() => {
                        this.setState({
                            identifyKey: ''
                        })
                    }}
                    identifyKey={identifyKey} />}
            </Layout>
        );
    }
}
function mapStateToProps (state, props) {
    return {
        themeConfig: state.theme.themeConfig,
        panes: state.app.panes,
        selectedKeys: [state.app.activeKey],
        activeKey: props.location.pathname || state.app.activeKey,
        openKeys: state.app.openKeys || ['/' + props.location.pathname.split('/')[1]],
        defaultSelectedKeys: [props.location.pathname || '/'] || '',
        defaultOpenKeys: ['/' + props.location.pathname.split('/')[1]] || '',
        permissionCodes: state.app.permissionCodes,
        globalConfig: config,
        $http: axios,
        $baseUrl: baseUrl,
        $appUrl: appUrl,
        routes: state.app.routes,
        aliveApps: state.app.aliveApps || [],
        isIframeActive: state.app.aliveApps.map(val => val.path).includes(props.location.pathname || state.app.activeKey),
        userInfo: state.app.userInfo || storage.get('userInfo'),
    }
}
//需要触发什么行为
function mapDispatchToProps (dispatch, props) {
    return {
        onLogoClick: (activeKey) => {
            dispatch({
                type: CHANGE_TABS,
                ...props,
                activeKey
            })
        },
        setTheme: (val) => {
            dispatch({
                type: 'setTheme',
                value: {
                    ...val
                }
            })
        },
        addTabs: async (val) => {
            await dispatch({
                type: ADD_TABS,
                ...props,
                ...val
            })
        },
        removeTabs: (activeKey, isCloseOpen) => {
            dispatch({
                type: REMOVE_TABS,
                activeKey,
                isCloseOpen,
                ...props
            })
        },
        changeTabs: (val) => {
            dispatch({
                type: CHANGE_TABS,
                ...props,
                ...val
            })
        },
        filterTabs: (val) => {
            dispatch({
                type: FILTER_TABS,
                value: val,
                ...props
            })
        },
        logout: (value) => {
            dispatch({
                type: 'logout',
                value,
                ...props
            })
        },
        setUserInfo: (value) => {
            dispatch({
                type: 'setUserInfo',
                value,
                ...props
            })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BaseLayout));
