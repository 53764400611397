// import { registerMicroApps, start } from 'qiankun';
// const getActiveRule = (hash) => (location) => location.hash.startsWith(hash)
// registerMicroApps(
//     Object.keys(apps).map(key => ({
//         ...apps[key],
//         entry: apps[key].url,
//         // activeRule: '#' + apps[key].baseRoute,
//         activeRule: getActiveRule('#' + apps[key].baseRoute),
//     }))
// );
// // 启动 qiankun
// start();
import { storage } from '@/utils/storage'
import {
    ShopOutlined,
    OrderedListOutlined,
    FundOutlined,
    ShoppingOutlined,
    AccountBookOutlined,
    UsergroupAddOutlined,
    CreditCardOutlined,
    ContactsOutlined,
    ShopFilled,
    // AppstoreAddOutlined,
} from '@ant-design/icons';
const apps = {
    selection: {
        url: 'http://localhost:1001',
        name: 'selection',
        code: 'project_submission_manage',
        icon: <ShoppingOutlined />,
        container: '.yzh-body',
        baseRoute: '/selection',
        title: '选品管理',
    },
    goods: {
        url: 'http://192.168.30.58:1025',
        name: 'goods',
        code: 'goods_center',
        icon: <ShoppingOutlined />,
        container: '.yzh-body',
        baseRoute: '/goods',
        title: '商品中心',
    },
    order: {
        url: 'http://192.168.5.34:1032',
        name: 'order',
        code: 'order_center',
        icon: <OrderedListOutlined />,
        container: '.yzh-body',
        baseRoute: '/order',
        title: '订单中心',
    },
    stock: {
        url: 'http://localhost:1003',
        name: 'stock',
        code: 'stock_center_manage',
        icon: <OrderedListOutlined />,
        container: '.yzh-body',
        baseRoute: '/stock',
        title: '库存管理',
    },
    operation: {
        url: 'http://localhost:8080/',
        name: 'operation',
        code: 'operation_center',
        icon: <FundOutlined />,
        icons: [<ContactsOutlined key={'ContactsOutlined'} />,
        <ShopOutlined key={'ShopOutlined'} />,
        <UsergroupAddOutlined key={'UsergroupAddOutlined'} />,
        <CreditCardOutlined key={'CreditCardOutlined'} />,
        ],
        container: '.yzh-body',
        baseRoute: '/operation',
        title: '运营中心',
    },
    supplier: {
        url: 'http://localhost:1005/',
        name: 'supplier',
        code: 'supplier_center',
        icon: <ShopFilled />,
        container: '.yzh-body',
        baseRoute: '/supplier',
        title: '供应商管理',
    },
    finance: {
        url: 'http://localhost:1006/',
        name: 'finance',
        code: 'finance_center',
        icon: <AccountBookOutlined />,
        container: '.yzh-body',
        baseRoute: '/finance',
        title: '财务结算',
    },
    enterprise: {
        url: 'http://ec-show.haoxiny.com/',
        name: 'enterprise',
        icon: <ShopOutlined />,
        container: '.yzh-body',
        baseRoute: '/enterprise'
    }
}
switch (process.env.REACT_APP_ENV) {
    case 'development':
        apps.goods.url = 'http://product.test.limofang.cn'
        apps.selection.url = 'http://selection-admin.test.limofang.cn'
        // apps.order.url = 'http://order.test.limofang.cn'
        apps.order.url = 'http://192.168.5.34:1032'
        apps.operation.url = 'http://operate.test.limofang.cn'
        apps.supplier.url = 'http://supplier.test.limofang.cn'
        apps.finance.url = 'http://finance.test.limofang.cn'
        // apps.stock.url = 'http://192.168.3.207:1031'
        apps.stock.url = 'http://inventory.test.limofang.cn'


        apps.goods.url = 'http://192.168.5.105:1001'
        apps.order.url = 'http://192.168.5.105:1003'
        apps.operation.url = 'http://192.168.5.105:8080'
        apps.supplier.url = 'http://192.168.5.105:1005'
        apps.finance.url = 'http://192.168.5.105:1001'
        // apps.order.url = 'http://192.168.3.207:1026'
        break
    case 'test':
        apps.goods.url = 'http://product.test.limofang.cn'
        apps.selection.url = 'http://selection-admin.test.limofang.cn'
        apps.order.url = 'http://order.test.limofang.cn'
        apps.operation.url = 'http://operate.test.limofang.cn'
        apps.supplier.url = 'http://supplier.test.limofang.cn'
        apps.finance.url = 'http://finance.test.limofang.cn'
        apps.stock.url = 'http://inventory.test.limofang.cn'
        break
    case 'jindie':
        apps.goods.url = 'http://product.jindie.limofang.cn'
        apps.order.url = 'http://order.jindie.limofang.cn'
        apps.operation.url = 'http://operate.jindie.limofang.cn'
        apps.supplier.url = 'http://supplier.jindie.limofang.cn'
        apps.finance.url = 'http://finance.jindie.limofang.cn'
        apps.stock.url = 'http://inventory.test.limofang.cn'
        break
    case 'jindie-hd':
        apps.goods.url = 'http://product.jindie-hd.limofang.cn'
        apps.order.url = 'http://order.jindie-hd.limofang.cn'
        apps.operation.url = 'http://operate.jindie-hd.limofang.cn'
        apps.supplier.url = 'http://supplier.jindie-hd.limofang.cn'
        apps.finance.url = 'http://finance.jindie-hd.limofang.cn'
        apps.stock.url = 'http://inventory.test.limofang.cn'
        break
    case 'test219':
        apps.goods.url = 'http://192.168.1.219:3001'
        apps.order.url = 'http://order.219.limofang.cn'
        apps.operation.url = 'http://operate.219.limofang.cn'
        apps.supplier.url = 'http://192.168.1.219:3003'
        apps.finance.url = 'http://finance.219.limofang.cn'
        apps.stock.url = 'http://order.219.limofang.cn'
        break
    case 'dev':
        apps.goods.url = 'http://192.168.1.194:81'
        apps.order.url = 'http://192.168.1.194:84'
        apps.operation.url = 'http://192.168.1.194:83'
        apps.supplier.url = 'http://192.168.1.194:85'
        apps.finance.url = 'http://192.168.1.194:82'
        break
    case 'show':
        apps.goods.url = 'https://product-show.yunzhonghe.com'
        apps.order.url = 'https://order-show.yunzhonghe.com'
        apps.selection.url = 'https://selection-admin-show.yunzhonghe.com'
        apps.operation.url = 'https://operate-show.yunzhonghe.com'
        apps.supplier.url = 'https://supplier-show.yunzhonghe.com'
        apps.finance.url = 'https://finance-show.yunzhonghe.com'
        apps.stock.url = 'https://inventory-show.yunzhonghe.com'
        break

    case 'ipo':
        apps.goods.url = 'https://product.yunzhmall.com'
        apps.order.url = 'https://order.yunzhmall.com'
        apps.operation.url = 'https://operate.yunzhmall.com'
        apps.supplier.url = 'https://supplier.yunzhmall.com'
        apps.finance.url = 'https://fin.yunzhmall.com'
        apps.stock.url = 'https://inventory.yunzhmall.com'
        break
    default:
        apps.goods.url = 'https://product.yunzhonghe.com'
        apps.order.url = 'https://order.yunzhonghe.com'
        apps.selection.url = 'https://selection-admin.yunzhonghe.com'
        apps.operation.url = 'https://operate.yunzhonghe.com'
        apps.supplier.url = 'https://supplier.yunzhonghe.com'
        apps.finance.url = 'https://fin.yunzhonghe.com'
        apps.stock.url = 'https://inventory.yunzhonghe.com'
        // apps.stock.url = 'http://192.168.3.207:1031'
        break
}
const config = {
    inline: process.env.NODE_ENV !== 'production', // 是否使用内联script 默认值false
    destory: true, // 卸载时是否强制删除缓存资源 默认值false
    disableScopecss: false, // 禁用样式隔离 默认值false
    disableSandbox: false, // 禁用js沙箱 默认值false
    macro: true, // 以宏任务方式绑定元素作用域 默认值false
    shadowDOM: false, // 是否开启shadowDOM 默认值false
    preFetchApps: [
        // apps.product
        // ...Object.keys(apps).map(key => apps[key])
    ],
    // lifeCycles: {
    //     created (e) {
    //         console.log('created')
    //     },
    //     beforemount (e) {
    //         console.log('beforemount')
    //     },
    //     mounted (e) {
    //         console.log('mounted')
    //     },
    //     unmount (e) {
    //         console.log('unmount')
    //     },
    //     error (e) {
    //         console.log('error')
    //     }
    // }
}
const getFullLink = (baseUrl, pathname) => {
    let path = pathname.split('/').filter(el => el)
    path = '/#/' + path.slice(1, path.length).join('/')
    baseUrl += path
    baseUrl += (path.includes('?') ? '&' : '?')
    baseUrl += 'token=' + storage.get('token', 'cookie')
    baseUrl += '&userId=' + storage.get('userInfo')?.userId
    // console.log(baseUrl, 'baseUrl + path');
    return baseUrl
}
export {
    config,
    apps,
    getFullLink
}